/* eslint-disable import/no-anonymous-default-export */
/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import Cookie, { getQueryParams, getChunks } from "./helpers.js";
import {subscribeOnStream, unsubscribeFromStream} from "./streaming.js"

let data = [];


const MAX_ALLOWED_API_CALL = 50;
let apiCallCount = 0;

let lastBarsCache = new Map();

// TODO: Move these functions to utils and make use of those in other files as well. (streaming.js)

function checkEnvironment() {
  const origin = window.location.origin;

  if (origin === 'https://charts.wealthy.in') {
    return true;
  } else {
    return false;
  }
}

// const url = "https://charts.wealthydev.in/redirect?auth='xyz'&en='NSE'&token='3045'&ts='SBIN-EQ'";

const isProd = checkEnvironment();

const baseURL = isProd ? "https://api.wealthy.in/broking" : "https://api.wealthydev.in/broking";
const searchBaseURL = isProd ? "https://api.wealthy.in/scout" : "https://api.wealthydev.in/scout";
const hasAuthCookie = Cookie.getCookie("wl_authorization");
const prodToken = hasAuthCookie ? hasAuthCookie : "7dfaf86c-5bf1-45a4-8777-2103a1dfca61:KhDWgG2Kg2t1hb1m4VfySMjBa";
const devToken = hasAuthCookie ? hasAuthCookie : "30ed49c9-a0de-4d16-860e-5591f86d4e53:ANvKLqnKH76IMclEWStPInkUa";

const exchangeNameMap = {
  1: "NSE",
  2: "NFO",
  3: "BSE",
  4: "BFO"
};

const fullURL = window.location.href;
const queryParams = getQueryParams(fullURL);

const configurationData = {
  supported_resolutions: ["1", "3", "5", "30", "15", "1H", "4H", "1D", "1W", "1M"],
  exchanges: [
    {
      value: 'All',
      name: 'All',
      desc: 'All',
    },
    {
      value: 'NSE',
      name: 'NSE',
      desc: 'NSE',
    },
    {
      value: "BSE",
      name: "BSE",
      desc: "BSE"
    }
  ]
};

export default {
	onReady: (callback) => {
		setTimeout(() => callback(configurationData));
	},

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback,
) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", isProd ? prodToken : devToken);
      myHeaders.append("X-APP-VERSION", "broking-web");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const res = await fetch(`${searchBaseURL}/v0/search/?q=${userInput}&pt=stocks`, requestOptions)
      if (res.status === 200) {
        data = await res.json();

        let sampleSymbol = {};
          const newSymbols = [];
          const stocks = data?.stocks || [];
          const fno = data?.fno || [];
          data = [
            ...stocks,
            ...fno
          ]

          data.map((eachData) => {
            const name = eachData.trading_symbol || eachData.name
            const instrumentType = eachData.instrument_type
            const sampleTicker = name + ":" + eachData.token + ":" + exchangeNameMap[eachData.exchange_name] + ":" + instrumentType
            sampleSymbol = {}

            sampleSymbol.symbol = name,
            sampleSymbol.full_name = sampleTicker,
            sampleSymbol.token = eachData.token,
            sampleSymbol.description = name,
            sampleSymbol.exchange = exchangeNameMap[eachData.exchange_name],
            sampleSymbol.type = instrumentType,
            newSymbols.push(sampleSymbol);
          });
          onResultReadyCallback(newSymbols);
      } else {
        onResultReadyCallback([]);
      }
    } catch (error) {
      console.error(error)
    }
},

	resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    try {
      const splitValue = symbolName.split(":");

      if (symbolName && symbolName.length > 0) {
        const symbolInfo = {
          ticker: queryParams?.token,
          name: queryParams?.ts,
          description: queryParams?.ts,
          type: queryParams?.insType ?? "Stock",
          session: '2;0915-1530:23456',
          exchange: queryParams?.en || "NSE",
          timezone: 'Asia/Kolkata',
          minmov: 1,
          pricescale: 100,
          supported_resolution: ['1', '3', '5', '30', '15', '1H', '4H', '1D', '1W', '1M'],
          has_intraday: true,
          visible_plots_set: queryParams?.insType === "INDEX" ? "ohlc" : 'ohlcv',
          has_weekly_and_monthly: true,
          volume_precision: 2,
          data_status: 'streaming'
        };
        onSymbolResolvedCallback(symbolInfo);
      } else {
      }
    } catch (error) {
      onResolveErrorCallback(error);
    }
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    let { from, to, firstDataRequest } = periodParams;
    apiCallCount = apiCallCount + 1;

    const resolutionResolver = {
      "1M": 1113,
      "1W": 1112,
      "1D": 1111,
      "30": 30,
      "60": 60,
      "240": 240,
      "1": 1,
      "15": 15,
      "3": 3,
      "5": 5,
    };

    const exchangeNameReverseMap = {
      NSE: 1,
      NFO: 2,
      BSE: 3,
      BFO: 4,
    };

    const selectedResolution = resolutionResolver[resolution];
    const chunks = getChunks(from, to, resolution);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", isProd ? prodToken : devToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-APP-VERSION", "broking-web");

    try {
      let allBars = [];

      for (let chunk of chunks) {
        const raw = JSON.stringify({
          exchange_name: exchangeNameReverseMap[symbolInfo.exchange] ?? 1,
          token: symbolInfo.ticker ?? "3045",
          trading_symbol: symbolInfo.name ?? "SBIN-EQ",
          start_time: chunk.from,
          end_time: chunk.to,
          interval: selectedResolution,
          params: symbolInfo.type === "INDEX" ? "o,h,l,c" : "o,h,l,c,v",
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        const res = await fetch(`${baseURL}/api/v0/stock/chart-data/`, requestOptions);

        if (res.status === 200) {
          apiCallCount = 0;
          const data = await res.json();
          if (data && data.length) {
            const bars = data.map((bar) => {
              const barTime = new Date(bar.interval.d);

              if (["1D", "1W", "1M"].includes(resolution)) {
                barTime.setMinutes(barTime.getMinutes() - barTime.getTimezoneOffset());
              }

              return {
                time: barTime.getTime(),
                low: parseFloat(bar.interval.l),
                high: parseFloat(bar.interval.h),
                open: parseFloat(bar.interval.o),
                close: parseFloat(bar.interval.c),
                volume: parseFloat(bar.interval.v) || 0,
              };
            });

            allBars.push(...bars);
          }
        }
      }

      if (allBars.length) {
        allBars.sort((a, b) => a.time - b.time);

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, { ...allBars[allBars.length - 1] });
        }

        onHistoryCallback(allBars, { noData: false });
      } else {
        if (apiCallCount <= MAX_ALLOWED_API_CALL) {
          onHistoryCallback([], {noData: false})
        } else {
          onHistoryCallback([], { noData: true });
        }
      }
    } catch (error) {
      onErrorCallback(error);
    }
  },


	// getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
  //   let {from, to, firstDataRequest} = periodParams;

  //   const resolutionResolver = {
  //     "1M": 1113,
  //     "1W": 1112,
  //     "1D": 1111,
  //     "30": 30,
  //     "60": 60,
  //     "240": 240,
  //     "1": 1,
  //     "15": 15,
  //     "3": 3,
  //     "5": 5
  //   };
  //   const selectedResolution = resolutionResolver[resolution];

  //   const exchangeNameReverseMap = {
  //     "NSE": 1,
  //     "NFO": 2,
  //     "BSE": 3,
  //     "BFO": 4
  //   };

  //   // console.log(from, to, "from get bars")

  //   // const resolutionBuffer = {
  //   //   "1": 3600 * 24 * 4,
  //   //   "3": 3600 * 24 * 4,
  //   //   "5": 3600 * 24 * 4,
  //   //   "15": 3600 * 24 * 7,
  //   //   "30": 3600 * 24 * 7,
  //   //   "60": 3600 * 24 * 7,
  //   //   "240": 3600 * 24 * 30,
  //   //   "1D": 3600 * 24 * 365,
  //   //   "1W": 3600 * 24 * 365,
  //   //   "1M": 3600 * 24 * 365 * 2
  //   // };

  //   const chunks = getChunks(from, to, resolution)

  //   console.log(chunks, "chunk list")

  //   // if (!(resolution === "1W") || !(resolution === "1M")) {
  //   //   const buffer = resolutionBuffer[resolution] || 0;

  //   //   if (buffer > 0) {
  //   //     const now = Math.floor(Date.now() / 1000);
  //   //     to = to || now;
  //   //     from = to - buffer;
  //   //   }
  //   // }


  //     try {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Authorization", isProd ? prodToken : devToken);
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append("X-APP-VERSION", "broking-web");

  //       const {
  //         ticker,
  //         name,
  //         exchange,
  //         type
  //       } = symbolInfo;


  //       // const updatedTime = adjustEpochTimes(from, to);

  //       // const raw = JSON.stringify({
  //       //   "exchange_name": exchangeNameReverseMap[exchange] ?? 1,
  //       //   "token": ticker ?? "3045",
  //       //   "trading_symbol": name ?? "SBIN-EQ",
  //       //   "start_time" : from,
  //       //   "end_time" : to,
  //       //   "interval": selectedResolution,
  //       //   "params": type === "INDEX" ? "o,h,l,c" : "o,h,l,c,v"
  //       // });

  //       // const requestOptions = {
  //       //   method: 'POST',
  //       //   headers: myHeaders,
  //       //   body: raw
  //       // };

  //       for (let chunk of chunks) {
  //         const raw = JSON.stringify({
  //           "exchange_name": exchangeNameReverseMap[exchange] ?? 1,
  //           "token": ticker ?? "3045",
  //           "trading_symbol": name ?? "SBIN-EQ",
  //           "start_time": chunk.from,
  //           "end_time": chunk.to,
  //           "interval": selectedResolution,
  //           "params": type === "INDEX" ? "o,h,l,c" : "o,h,l,c,v"
  //         });

  //         const requestOptions = {
  //           method: 'POST',
  //           headers: myHeaders,
  //           body: raw
  //         };

  //         const res = await fetch(`${baseURL}/api/v0/stock/chart-data/`, requestOptions);

  //         if (res.status === 200 && res?.data !== null) {
  //           data = await res.json();
  //           let bars = [];

  //           if (data && data.length) {

  //             data.forEach(bar => {
  //               const barTime = new Date(bar.interval.d);

  //               if (resolution === "1D" || resolution === "1W" || resolution === "1M") {
  //                 barTime.setMinutes(barTime.getMinutes() - barTime.getTimezoneOffset());
  //               }

  //               let timeForBar = barTime.getTime();


  //               bars = [...bars, {
  //                 time: timeForBar,
  //                 low: parseFloat(bar.interval.l),
  //                 high: parseFloat(bar.interval.h),
  //                 open: parseFloat(bar.interval.o),
  //                 close: parseFloat(bar.interval.c),
  //                 volume: parseFloat(bar.interval.v)
  //               }];
  //             });

  //             if (firstDataRequest) {
  //               lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
  //             }
  //             onHistoryCallback(bars, { noData: false });
  //           } else {
  //             apiCallCount = apiCallCount + 1;
  //             if (apiCallCount <= MAX_ALLOWED_API_CALL) {
  //               onHistoryCallback([], { noData: false });
  //             } else {
  //               onHistoryCallback([], { noData: true });
  //             }
  //           }
  //         } else {
  //           apiCallCount = apiCallCount + 1;
  //           if (apiCallCount <= MAX_ALLOWED_API_CALL) {
  //             onHistoryCallback([], { noData: false });
  //           } else {
  //             onHistoryCallback([], { noData: true });
  //           }
  //         }

  //       }

  //       // const res = await fetch(`${baseURL}/api/v0/stock/chart-data/`, requestOptions);

  //       // if (res.status === 200 && res?.data !== null) {
  //       //   data = await res.json();
  //       //   let bars = [];

  //       //   if (data && data.length) {

  //       //     data.forEach(bar => {
  //       //       const barTime = new Date(bar.interval.d);

  //       //       if (resolution === "1D" || resolution === "1W" || resolution === "1M") {
  //       //         barTime.setMinutes(barTime.getMinutes() - barTime.getTimezoneOffset());
  //       //       }

  //       //       let timeForBar = barTime.getTime();


  //       //       // if (barTime >= from && barTime <= to) {
  //       //         bars = [...bars, {
  //       //           time: timeForBar,
  //       //           low: parseFloat(bar.interval.l),
  //       //           high: parseFloat(bar.interval.h),
  //       //           open: parseFloat(bar.interval.o),
  //       //           close: parseFloat(bar.interval.c),
  //       //           volume: parseFloat(bar.interval.v)
  //       //         }];
  //       //       // }
  //       //     });

  //       //     // bars = bars.filter(bar => bar.time >= from * 1000 && bar.time <= to * 1000);
  //       //     // bars.sort((a, b) => a.time - b.time);

  //       //     if (firstDataRequest) {
  //       //       lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
  //       //     }
  //       //       onHistoryCallback(bars, {noData: false});
  //       //     } else {
  //       //     apiCallCount = apiCallCount + 1;
  //       //     if (apiCallCount <= MAX_ALLOWED_API_CALL) {
  //       //       onHistoryCallback([], { noData: false });
  //       //     } else {
  //       //       onHistoryCallback([], { noData: true });
  //       //     }
  //       //     }
  //       // } else {
  //       //   apiCallCount = apiCallCount + 1;
  //       //   if (apiCallCount <= MAX_ALLOWED_API_CALL) {
  //       //     onHistoryCallback([], { noData: false });
  //       //   } else {
  //       //     onHistoryCallback([], { noData: true });
  //       //   }
  //       // }
  //     } catch (error) {
  //       onErrorCallback(error);
  //     }
  // },

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
    const {
      ticker,
      exchange
    } = symbolInfo;

    subscriberUID = `${exchange}|${ticker}`;

    subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, lastBarsCache.get(symbolInfo.full_name));
  },

  unsubscribeBars: (subscriberUID) => {
    console.log("this is executed first", subscriberUID);
    unsubscribeFromStream(subscriberUID);
  },
}
/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

const resolutionBuffer = {
  "1": 3600 * 24 * 4,
  "3": 3600 * 24 * 4,
  "5": 3600 * 24 * 4,
  "15": 3600 * 24 * 7,
  "30": 3600 * 24 * 7,
  "60": 3600 * 24 * 7,
  "240": 3600 * 24 * 30,
  "1D": 3600 * 24 * 365,
  "1W": 3600 * 24 * 365,
  "1M": 3600 * 24 * 365 * 5
};

export function getQueryParams(url) {
  const queryParams = {};
  const urlSearchParams = new URLSearchParams(url.slice(url.indexOf('?') + 1));

  for (const [key, value] of urlSearchParams.entries()) {
    queryParams[key] = decodeURIComponent(value);
  }

  return queryParams;
};

export function adjustEpochTimes(fromEpochSeconds, toEpochSeconds) {
  // const IST_OFFSET = 5.5 * 60 * 60 * 1000;
  const IST_OFFSET = 0;

  // Convert input from seconds to milliseconds
  const fromEpoch = fromEpochSeconds * 1000;
  const toEpoch = toEpochSeconds * 1000;

  // Convert 'from' epoch to the start of the same day (00:00 IST)
  const fromDate = new Date(fromEpoch + IST_OFFSET);
  const adjustedFromDate = new Date(
    fromDate.getFullYear(),
    fromDate.getMonth(),
    fromDate.getDate(),
    0, 0, 0, 0
  );

  // Convert 'to' epoch to the end of the same day (23:59 IST)
  const toDate = new Date(toEpoch + IST_OFFSET);
  const adjustedToDate = new Date(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
    23, 59, 59, 999
  );

  // Convert back to epoch and adjust for IST offset
  const adjustedFromEpoch = Math.floor((adjustedFromDate.getTime() - IST_OFFSET) / 1000);
  const adjustedToEpoch = Math.floor((adjustedToDate.getTime() - IST_OFFSET) / 1000);

  // Return the adjusted values
  return { from: adjustedFromEpoch, to: adjustedToEpoch };

};

export function getChunks(initialFrom, initialTo, resolution) {


  const buffer = resolutionBuffer[resolution] || 0;
  // console.log(initialFrom, initialTo, buffer, "from chunks")
  if (buffer <= 0) {
    throw new Error("Invalid or unsupported resolution.");
  }

  const chunks = [];
  let currentTo = Math.max(initialTo, 0);
  let currentFrom = Math.max(initialFrom, 0);

  // console.log(currentTo-buffer, "from chunkss mee")

  // const isMinuteResolution = ['1', '3', '5', '15', '30'].includes(resolution);


    while (currentTo > currentFrom) {
      const chunkFrom = Math.min(currentFrom, currentTo - buffer);
      const chunkTo = currentTo;

      chunks.push({
        from: chunkFrom,
        to: chunkTo,
      });

      currentTo = chunkFrom;
    }


  chunks.sort((a, b) => a.from - b.from);

  return chunks || [];
};


export const removeAuthFromHref = (oldHref)=>{
  const baseUrl = oldHref.split("?")[0];
  const filteredQueryArr = oldHref.split("?")[1].split("&").filter((param=>param.split("=")[0]!=="auth"));
  let filteredQueryParams = "";
  filteredQueryArr.map((ele,i)=>{
    if(i!=filteredQueryArr.length-1)ele+="&"
    filteredQueryParams+=ele;
  });
  return baseUrl+"?"+filteredQueryParams;
}

class Cookie{
  static setCookie = (key,value)=>{
    document.cookie = `${key}=${value}`;
  }

  static getCookie = (key)=>{
    return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1];
  }
}
export default Cookie;


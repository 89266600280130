//
// Author - Brijesh Pandey
// Git - https://github.com/Brijesh-Pandey
//

import Cookie from "./helpers";

function checkEnvironment() {
  const origin = window.location.origin;

  if (origin === 'https://charts.wealthy.in') {
    return true;
  } else {
    return false;
  }
}

const isProd = checkEnvironment();



const baseURL = isProd ? "https://api.wealthy.in/broking" : "https://api.wealthydev.in/broking";
const hasAuthCookie = Cookie.getCookie("wl_authorization");

const prodToken = hasAuthCookie ? hasAuthCookie : "7dfaf86c-5bf1-45a4-8777-2103a1dfca61:KhDWgG2Kg2t1hb1m4VfySMjBa";
const devToken = hasAuthCookie ? hasAuthCookie : "30ed49c9-a0de-4d16-860e-5591f86d4e53:ANvKLqnKH76IMclEWStPInkUa";

let data = null;

let receivedData = null;
const channelToSubscription = new Map();


const secondsMap = {
  "1": 60,
  "3": 180,
  "5": 300,
  "15": 900,
  "30": 1800,
  "60": 3600,
  "240": 4 * 3600,
  "1D": 24 * 3600,
  "1W": 7 * 24 * 3600,
  "1M": 30 * 24 * 3600
};


const getData = async (symbol, subscriberUID, resolution, lastDailyBar) => {
  let prevEpoch = lastDailyBar?.time ||  0;

  let initBar = {
    open: Number.NEGATIVE_INFINITY,
    high: Number.NEGATIVE_INFINITY,
    low: Number.POSITIVE_INFINITY
  };

  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", isProd ? prodToken : devToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-APP-VERSION", "broking-web");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };


    const res = await fetch(`${baseURL}/api/v0/auth/ws-url/?source=WEB`, requestOptions)
    data = await res.json();

    const {
      name,
      ticker,
      exchange
    } = symbol

    if (data) {
      const socketBaseUrl = isProd ? "wss://trade.wealthy.in" : "ws://noren.wealthydev.in"
      const connectionString = `${socketBaseUrl}${data.base_url}`;
      const authorizationBody = {...data.body}

      const webSocket = new WebSocket(connectionString);

      webSocket.onopen = (event) => {
        webSocket.send(JSON.stringify(authorizationBody));
      };

      webSocket.onmessage = (event) => {
        receivedData = JSON.parse(event.data);
        const subscriptionStockPayload = {
          t: "t",
          k: subscriberUID ??  "NSE|3045"
      }
        if (receivedData.t === "ck") {
          webSocket.send(JSON.stringify(subscriptionStockPayload));
          return;
        }

        const currentDate = new Date();

        // let epochTimeInMinutes = Math.floor(currentDate.getTime() / (60 * 1000));
        // epochTimeInMinutes *= 60000;
        let candleStartTime = 0;
        const isWeekOrMonthSelected = ['1W', '1M'].includes(resolution);
        if (isWeekOrMonthSelected) {
          candleStartTime = lastDailyBar?.time;
        } else {
          candleStartTime = Math.floor(currentDate.getTime() / (secondsMap[resolution] * 1000));
          candleStartTime *= secondsMap[resolution] * 1000;
        }
        let epochTimeInMinutes = candleStartTime;

        console.log(prevEpoch,epochTimeInMinutes, lastDailyBar, receivedData, "from mee")


        if (epochTimeInMinutes >= prevEpoch) {
          if (receivedData?.lp) {
            if (resolution === "1D" || resolution === "1W" || resolution === "1M") {

              if (resolution === "1W" || resolution === "1M") {
                initBar = {
                  time: lastDailyBar.time,
                  open: lastDailyBar.open || receivedData.o || initBar.open,
                  high: Math.max(receivedData.h || Number.NEGATIVE_INFINITY, Math.max(initBar.high, lastDailyBar.high || Number.NEGATIVE_INFINITY)),
                  low: Math.min(receivedData.l || Number.POSITIVE_INFINITY, Math.min(initBar.low, lastDailyBar.low || Number.POSITIVE_INFINITY)),
                  close: receivedData.lp
                }
              } else {
                initBar = {
                  time: receivedData.ft,
                  open: receivedData.o || initBar.open,
                  high: Math.max(receivedData.h || Number.NEGATIVE_INFINITY, initBar.high),
                  low: Math.min(receivedData.l || Number.POSITIVE_INFINITY, initBar.low),
                  close: receivedData.lp
                }
              }

            } else {
              initBar = {
                time: receivedData.ft,
                open: receivedData.lp,
                high: receivedData.lp,
                low: receivedData.lp,
                close: receivedData.lp
              }
            }
            prevEpoch = epochTimeInMinutes
          }

          console.log(initBar, receivedData, epochTimeInMinutes, prevEpoch, resolution, lastDailyBar, "inside new candle");
        } else {
          if(receivedData?.lp) {
            if(initBar.open === Number.NEGATIVE_INFINITY) {
              initBar.open = receivedData.lp
            }
            initBar.time = epochTimeInMinutes
            initBar.high = Math.max(receivedData.lp || Number.NEGATIVE_INFINITY, initBar.high)
            initBar.low = Math.min(receivedData.lp || Number.POSITIVE_INFINITY, initBar.low)
            initBar.close = receivedData.lp
          }


          console.log(initBar, receivedData, epochTimeInMinutes, prevEpoch, resolution, lastDailyBar, "inside else ");
        }

        console.log(initBar, "after if else");

        if(receivedData?.lp) {
          initBar.time = epochTimeInMinutes
          initBar.close = receivedData.lp
        }

        if(initBar?.open !== Number.NEGATIVE_INFINITY) {
          const channelString = subscriberUID;
          const subscriptionItem = channelToSubscription.get(channelString);
          subscriptionItem.lastDailyBar = initBar;
          subscriptionItem.handlers.forEach(handler => handler.callback(initBar));
        }
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar
)
{
  getData(symbolInfo, subscriberUID, resolution, lastDailyBar)
  // console.log(subscriberUID, "from subscribe on stream function")

    const parsedSymbol = symbolInfo.name;
    const channelString = subscriberUID;
    const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback
    };

  let subscriptionItem = channelToSubscription.get(channelString);

    if (subscriptionItem) {
        subscriptionItem.handlers.push(handler);
        return;
    }
    subscriptionItem = {
        subscriberUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
    };
    channelToSubscription.set(channelString, subscriptionItem);
};

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers
      .findIndex(handler => handler.id === channelString);


    if (handlerIndex !== -1) {
        // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
          console.log(subscriptionItem.handlers, channelString, channelToSubscription, "from unsub method")
            // TODO: Write unsubscribe logic here for kambala socket send channel string to unsubscribe
            channelToSubscription.delete(channelString);
            break;
        }
    }
}
}